import React from "react";
import logo from "../../assets/images/logo_blue.svg";
import { NavLink, Link } from "react-router-dom";

const NavBar = ({ onHambergerClickHandler }) => {
  return (
    <header className="header">
      <div className="header__desktop__container">
        <div className="header__logo-box">
          <Link to="/">
            <img src={logo} alt="logo" className="header__logo" />
          </Link>
        </div>
        <div className="hamberger-box" onClick={onHambergerClickHandler}>
          <span htmlFor="hamberger__checkbox" className="hamberger"></span>
        </div>

        <ul className="header__nav-items__container">
          <li className="header__nav-item">
            <NavLink to="/skills">Skills</NavLink>
          </li>
          <li className="header__nav-item">
            <NavLink to="/projects">Projects</NavLink>
          </li>
          <li className="header__nav-item">
            <NavLink to="/services">Services</NavLink>
          </li>
          <li className="header__nav-item">
            <NavLink to="/resume">Resume</NavLink>
          </li>
          <li className="header__nav-item">
            <NavLink to="/about">About</NavLink>
          </li>
          <li className="header__nav-item">
            <NavLink to="/contact">Contact</NavLink>
          </li>
        </ul>
      </div>
    </header>
  );
};

export default NavBar;
