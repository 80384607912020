import React, { useEffect } from "react";
import colorify_icon from "../../assets/images/colorify.png";
import calendar_icon from "../../assets/images/calendar.png";
import drumMachine_icon from "../../assets/images/drum_machine.png";
import cssResponsiveWebpage_icon from "../../assets/images/css_responsive_webpage.png";
import workflow_icon from "../../assets/images/workflow.png";
import reactVideoPlayer_icon from "../../assets/images/react_video_player.png";

class Slider {
  constructor({
    sliderSelector = ".projects-slider",
    sliderContainerSelector = ".projects-slider__container",
    previousSelector = ".previous",
    nextSelector = ".next",
    transitionDelay = 3000,
  } = {}) {
    this.slider = document.querySelector(sliderSelector);
    this.slides = document.querySelectorAll(
      `${sliderContainerSelector} img`
    ).length;
    this.sliderContainer = document.querySelector(sliderContainerSelector);
    this.previousBtn = document.querySelector(previousSelector);
    this.nextBtn = document.querySelector(nextSelector);

    this.slideSize = this.slider && this.slider.offsetWidth;
    this.currentSlide = 0;
    this.setEventListeners();
    this.generateShortcuts();
  }

  moveSlides() {
    this.sliderContainer.style.transform = `translate(-${
      this.currentSlide * this.slideSize
    }px)`;
    Array.from(this.shortcuts.children).forEach((shortcut) =>
      shortcut.classList.remove("active")
    );
    this.shortcuts.children[this.currentSlide].classList.add("active");
  }

  nextSlide() {
    this.currentSlide =
      this.currentSlide >= this.slides - 1 ? 0 : this.currentSlide + 1;
    this.moveSlides();
  }

  previousSlide() {
    console.log(this.currentSlide);
    this.currentSlide =
      this.currentSlide <= 0 ? this.slides - 1 : this.currentSlide - 1;
    this.moveSlides();
  }

  setEventListeners() {
    this.nextBtn.addEventListener("click", this.nextSlide.bind(this));
    this.previousBtn.addEventListener("click", this.previousSlide.bind(this));
  }

  generateShortcuts() {
    const shortcuts = document.createElement("div");
    shortcuts.classList.add("shortcuts");

    for (let i = 0; i < this.slides; ++i) {
      const shortcut = document.createElement("span");
      shortcut.addEventListener("click", () => {
        this.currentSlide = i;
        this.moveSlides();
      });
      shortcut.classList.add("shortcut");
      shortcuts.appendChild(shortcut);
    }

    shortcuts.firstChild.classList.add("active");
    this.slider.appendChild(shortcuts);
    this.shortcuts = shortcuts;
  }

  setAutoPlay(transitionTime) {
    setInterval(this.nextSlide.bind(this), transitionTime);
  }
}

const ProjectSlider = (props) => {
  useEffect(() => {
    new Slider();
  }, []);

  return (
    <div className="projects-slider shadow-down">
      <div className="projects-slider__container">
        <img
          src={colorify_icon}
          alt="project icon"
          className="projects__item"
        />
        <img
          src={workflow_icon}
          alt="project icon"
          className="projects__item"
        />
        <img
          src={reactVideoPlayer_icon}
          alt="project icon"
          className="projects__item"
        />
        <img
          src={drumMachine_icon}
          alt="project icon"
          className="projects__item"
        />
        <img
          src={cssResponsiveWebpage_icon}
          alt="project icon"
          className="projects__item"
        />
        <img
          src={calendar_icon}
          alt="project icon"
          className="projects__item"
        />
      </div>
      <div className="projects-slider__controls">
        <span className="previous">&larr;</span>
        <span className="next">&rarr;</span>
      </div>
      {/* <div className="slider__controls">
        <span className="previous">
          <img src="../node_modules/@icon/font-awesome/icons/caret-left.svg" alt="left">
        </span>
        <span className="next">
          <img src="../node_modules/@icon/font-awesome/icons/caret-right.svg" alt="right">
        
        </span>
      </div> */}
    </div>
  );
};

export default ProjectSlider;
