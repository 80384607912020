import React from "react";

const ServicesCard = ({ title, icon, tags }) => {
  return (
    <div className="services__card">
      <div className="services__card-logo-box">
        <img src={icon} alt="icon" className="services__card-logo" />
      </div>
      <h3 className="services__card-title align-center">{title}</h3>
      <div className="tags__container">
        {tags.map((tag, index) => (
          <span className="tag" key={index}>
            {tag}
          </span>
        ))}
      </div>
    </div>
  );
};

export default ServicesCard;
