import React from "react";
import twitter_icon from "../../assets/images/twitter.svg";
import github_icon from "../../assets/images/github.svg";
import linkedIn_icon from "../../assets/images/linkedin.svg";
import devCommunity_icon from "../../assets/images/dev_community.svg";
import instagram_icon from "../../assets/images/instagram.svg";

const Footer = () => {
  const contactData = [
    {
      id: 1,
      title: "Twitter",
      icon: twitter_icon,
      href: "https://twitter.com/aashish__7",
    },
    {
      id: 2,
      title: "Github",
      icon: github_icon,
      href: "https://github.com/Aashishkushwaha",
    },
    {
      id: 3,
      title: "LinkedIn",
      icon: linkedIn_icon,
      href: "https://www.linkedin.com/in/aashish-kushwaha-338385168/",
    },
    {
      id: 4,
      title: "Dev Community",
      icon: devCommunity_icon,
      href: "https://www.linkedin.com/in/aashish-kushwaha-338385168/",
    },
    {
      id: 5,
      title: "Instagram",
      icon: instagram_icon,
      href: "https://www.instagram.com/aashish.kushwaha7/",
    },
  ];

  return (
    <footer className="footer">
      <div className="footer__details">
        All Rights Reserved © 2020 | Designed and Developed by{" "}
        <a
          className="bold"
          href="https://twitter.com/aashish__7"
          rel="noopener noreferrer"
          target="_blank"
        >
          Aashish Kushwaha
        </a>
      </div>
      <div className="footer__contacts">
        {contactData.map((contact) => {
          return (
            <a
              key={contact.id}
              href={contact.href}
              rel="noopener noreferrer"
              target="_blank"
            >
              <img src={contact.icon} alt={contact.title} />
            </a>
          );
        })}
      </div>
    </footer>
  );
};

export default Footer;
