import React, { useState } from "react";
import project_illustration from "../../assets/images/coding_projects.svg";
import ProjectCard from "./ProjectCard";
import ProjectSlider from "./ProjectSlider";

const Projects = () => {
  let projects = ["one", "two", "three", "four", "five", "six", "seven"];
  const [currentProjectIndex, setCurrentProjectIndex] = useState(0);

  return (
    <section className="projects__section">
      <h1 className="heading fade-in__scale-animation heading__primary align-center">
        Work Projects
      </h1>
      <h3 className="heading heading__ternary light align-center">
        Below are some of my work projects which I created during self learning.
      </h3>
      <div className="projects__page-container">
        <div className="projects__illustration-box">
          <img
            src={project_illustration}
            alt="projects"
            className="projects__illustration"
          />
        </div>
        {/* <div className="projects__desktop__container">
          <ProjectCard
            title={projects[currentProjectIndex]}
            setCurrentProjectIndex={setCurrentProjectIndex}
          />
        </div> */}
        <ProjectSlider />
      </div>
    </section>
  );
};

export default Projects;
