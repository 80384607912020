import React from "react";
import about__illustration from "../../assets/images/about_me.svg";
import mentor__icon from "../../assets/images/praveen.jpeg";
import twitter__icon from "../../assets/images/twitter.svg";
import github__icon from "../../assets/images/github.svg";

const About = () => {
  return (
    <section className="about__section">
      <h1 className="heading fade-in__scale-animation heading__primary align-center">
        About Me
      </h1>
      <h3 className="heading heading__ternary light align-center">
        Below are some details about me I think you might find interesting.
      </h3>
      <div className="about__container">
        <div className="about__illustration-box">
          <img
            src={about__illustration}
            alt="about icon"
            className="about__illustration"
          />
        </div>
        <div className="about__info-container">
          I'm <h1 style={{ display: "inline-block" }}>Aashish Kushwaha</h1> from
          Pune, India.
          <ul className="about__info-list"></ul>
          <li className="about__info-item">
            I'm a self-taught JavaScript enthusiast, MERN stack developer.
          </li>
          <li className="about__info-item">
            Create simple & effective User interafaces with help of modern
            technologies which are maintainable & scalable.
          </li>
          <li className="about__info-item">
            Create website which are responsive & effectively permormant with
            repect to different device sizes & resolution.
          </li>
          <li className="about__info-item">
            Create websites which are more realistic & minimalistic in design.
          </li>
          <li className="about__info-item">
            Work on Progressive Web Application development as well.
          </li>
          <li className="about__info-item">
            Future short term goal is to explore the Mobile App development
            field.
          </li>
        </div>
      </div>
      <hr className="m-5" />
      <div className="mentor__section">
        <h3 className="heading heading__ternary light align-center">
          Special thanks & credits to
        </h3>
        <h1 className="heading heading__primary light align-center">
          Praveen Saraogi
        </h1>
        <div className="mentor__info-container">
          <div className="mentor__logo-box">
            <img
              src={mentor__icon}
              alt="praveen saraogi"
              className="mentor__logo"
            />
          </div>
          <div className="mentor__info">
            <p>
              Praveen Saraogi works as Senior Reactjs developer @Quinnox, Inc.,
              Bangalore.
            </p>
            <p>
              He's my mentor, guide & supporter. He always motivates & inspires
              me to do something new & innovative.
            </p>
          </div>
        </div>
        <h1 className="heading heading__ternary light align-center mt-3 mb-2">
          Feel free to reach out to Praveen
        </h1>
        <div className="mentor__contacts-container">
          <a
            href={"https://twitter.com/saraogipraveen"}
            rel="noopener noreferrer"
            target="_blank"
          >
            <img src={twitter__icon} alt={"twitter"} />
          </a>
          <a
            href={"https://github.com/saraogipraveen"}
            rel="noopener noreferrer"
            target="_blank"
          >
            <img src={github__icon} alt={"github"} />
          </a>
        </div>
      </div>
    </section>
  );
};

export default About;
