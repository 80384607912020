import React from "react";

const SkillCard = ({ icon, title, additional_info, additional_info_link }) => {
  console.log({ icon });
  return (
    <div className="skills__card shadow-down">
      <div className="skills__card__img-box">
        <img src={icon} alt={`${title} icon`} className="skills__card__img" />
      </div>
      <div className="skills__card--info-box">
        <h2 className="heading heading__ternary">{title}</h2>
        <p className="skills__card--info">{additional_info}</p>
        <a
          href={`${additional_info_link}`}
          rel="noopener noreferrer"
          target="_blank"
          className="more__info bold"
        >
          Learn {title}
        </a>
      </div>
    </div>
  );
};

export default SkillCard;
