import React from "react";
import { Link } from "react-router-dom";
// import web_illustration from "../../assets/images/javascript.svg";
import web_illustration from "../../assets/images/coding_langing_page.svg";

const Home = () => {
  return (
    <main className="home__section">
      <div className="home__info-box pt-5">
        <h1 className="main-heading">
          Hey,{" "}
          <span className="waving__hand" role="img" aria-label="waving hand">
            👋
          </span>{" "}
          I'm Aashish
        </h1>
        <h2 className="sub-heading--main">
          <span>Web</span> <span>Developer</span>
        </h2>
        <p className="sub-heading--sub mt-2">
          I create amazing web applications using latest technology.
        </p>
        <div className="cti__buttons-container mt-5">
          <Link to="/projects">
            <button className="btn btn__primary m-3 bold beat">
              See my work
            </button>
          </Link>
          <Link to="/contact">
            <button className="btn btn__secondary m-3 light">Contact Me</button>
          </Link>
        </div>
      </div>
      <div className="home__illustration--box">
        <img src={web_illustration} alt="web developer" />
      </div>
    </main>
  );
};

export default Home;
