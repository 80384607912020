import React from "react";
import { NavLink, Link } from "react-router-dom";
import logo from "../../assets/images/logo_white.svg";

const SideBar = ({ show }) => {
  const assignedClasses = ["sidebar__container"];
  if (show) assignedClasses.push("show__sidebar");

  return (
    <div className={assignedClasses.join(" ")}>
      <div className="sidebar__logo-box">
        <Link to="/">
          <img src={logo} alt="logo" className="sidebar__logo" />
        </Link>
      </div>
      <ul className="sidebar__items--container">
        <li className="sidebar__item">
          <NavLink to="/skills">Skills</NavLink>
        </li>
        <li className="sidebar__item">
          <NavLink to="/projects">Projects</NavLink>
        </li>
        <li className="sidebar__item">
          <NavLink to="/services">Services</NavLink>
        </li>
        <li className="sidebar__item">
          <NavLink to="/resume">Resume</NavLink>
        </li>
        <li className="sidebar__item">
          <NavLink to="/about">About</NavLink>
        </li>
        <li className="sidebar__item">
          <NavLink to="/contact">Contact</NavLink>
        </li>
      </ul>
    </div>
  );
};

export default SideBar;
