import React, { useState } from "react";
import { Switch, Route } from "react-router-dom";
import NavBar from "./components/NavBar/NavBar";
import Home from "./components/Home/Home";
import Footer from "./components/Footer/Footer";
import Contact from "./components/Contact/Contact";
import SideBar from "./components/SideBar/SideBar";
import Backdrop from "./components/Backdrop/Backdrop";
import Resume from "./components/Resume/Resume";
import Skills from "./components/Skills/Skills";
import Projects from "./components/Projects/Projects";
import About from "./components/About/About";
import Services from "./components/Services/Services";

function App() {
  const [showSideBar, setShowSideBar] = useState(false);

  const sideBarHandler = () => setShowSideBar(!showSideBar);

  return (
    <div className="App">
      <NavBar onHambergerClickHandler={sideBarHandler} />
      <SideBar show={showSideBar} />
      <Backdrop show={showSideBar} onClickHandler={sideBarHandler} />
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/skills" exact component={Skills} />
        <Route path="/projects" exact component={Projects} />
        <Route path="/services" exact component={Services} />
        <Route path="/about" exact component={About} />
        <Route path="/contact" exact component={Contact} />
        <Route path="/resume" exact component={Resume} />
      </Switch>
      <Footer />
      {/* <Home /> */}
    </div>
  );
}

export default App;
