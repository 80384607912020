import React from "react";
import ServicesCard from "./ServicesCard";
import { servicesData } from "../../data/appData";

const Services = (props) => {
  return (
    <section className="services__page-container">
      <h1 className="heading fade-in__scale-animation heading__primary align-center">
        Services
      </h1>
      <h3 className="heading heading__ternary light align-center">
        Pinch me for any of the below solutions.
      </h3>
      <div className="services__container mt-5">
        {servicesData.map((service) => {
          return (
            <ServicesCard
              title={service.title}
              icon={service.iconUrl}
              tags={service.tags}
            />
          );
        })}
      </div>
    </section>
  );
};

export default Services;
