import React from "react";
import skills__illustrator from "../../assets/images/skills.svg";
import { skillsData } from "../../data/appData";
import SkillCard from "./SkillCard";

const Skills = (props) => {
  return (
    <section className="skills-section__container">
      <h1 className="heading fade-in__scale-animation heading__primary align-center">
        Skills
      </h1>
      <h3 className="heading heading__ternary light align-center">
        During my self learning journey I've gained knowledge in below
        technologies.
      </h3>
      <div className="skills__illustrator-box">
        <img
          src={skills__illustrator}
          alt="Skills"
          className="skills__illustrator"
        />
      </div>
      <div className="flex__wrap mt-5">
        {skillsData.map((skill) => {
          return (
            <SkillCard
              icon={skill.iconUrl}
              title={skill.title}
              additional_info={skill.additional_info}
              additional_info_link={skill.additional_info_link}
            />
          );
        })}
      </div>
    </section>
  );
};

export default Skills;
