import React from "react";
import resume_illustration from "../../assets/images/resume_illustration.svg";

const Resume = () => {
  return (
    <section className="resume__container">
      <div className="resume__illustration-box">
        <img src={resume_illustration} alt="resume" className="illustration" />
      </div>
      <div className="resume__page-container">
        <div className="resume__page">This is the page;</div>
        <span>page</span>
      </div>
    </section>
  );
};

export default Resume;
