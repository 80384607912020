import React from "react";
import contact_illustration from "../../assets/images/contact_page.svg";
import new_message from "../../assets/images/new_mail.svg";

const Contact = () => {
  return (
    <section className="contact__section">
      <div className="m-5 align-center">
        <h1 className="heading fade-in__scale-animation heading__primary align-center">
          Want to Collaborate, Get in touch
        </h1>
        <h3 className="heading heading__ternary light align-center">
          I'm only one click away from you. Smash Send Message button !!!
        </h3>
      </div>
      <div className="contact__form__container">
        <form className="contact__form ">
          <div className="form__group">
            <input
              type="text"
              name="name"
              id="name"
              className="form__input"
              placeholder="Your name"
              required
            />
            <label htmlFor="name" className="form__label">
              Your name
            </label>
          </div>
          <div className="form__group">
            <input
              type="email"
              name="email"
              id="email"
              className="form__input"
              placeholder="Email Address"
              required
            />
            <label htmlFor="email" className="form__label">
              Email Address
            </label>
          </div>
          <div className="form__group">
            <textarea
              name="message"
              id="message"
              className="form__input form__textarea"
              placeholder="Message body"
              required
            />
            <label htmlFor="email" className="form__label">
              Message body
            </label>
          </div>
          <div className="form__group">
            <button className="btn btn__primary btn__primary--white bold flex m-auto beat">
              <img src={new_message} className="new_mail" alt="new mail" /> Send
              Message
            </button>
          </div>
        </form>
        <div className="contact__illustration-box">
          <img src={contact_illustration} alt="contact illustration" />
        </div>
      </div>
    </section>
  );
};

export default Contact;
